<template>
  <div>
    <a-modal v-model="visible" :title="null" :footer="null">
      <div class="transaction-message">
        <i
          class="lnr lnr-checkmark-circle h-full w-full mb-4"
          style="color: green; font-size: 2rem"
        />
        <p style="font-size: 1.5rem">Payment processed successfully.</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {},
  watch: {
    showModal: {
      handler(show) {
        this.visible = show;
      },
      immediate: true,
    },
    visible(show) {
      if (!show) {
        this.$emit("close-modal", show);
      }
    },
  },
};
</script>

<style scoped>
.transaction-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}
</style>
