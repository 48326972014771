<template>
  <a-table
    :loading="loadingGet"
    :columns="columns"
    :data-source="tableData"
    :customRow="customRow"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <span slot="entity" slot-scope="data">
      {{ getValueFromSource(data, "entity.information.name") }}
    </span>

    <span slot="total_price" slot-scope="data">
      {{ data.total_price | formatCurrency }}
    </span>

    <span slot="transaction_ref" slot-scope="data">
      {{ data.transaction_ref }}</span
    >

    <span slot="scanned" slot-scope="data">{{
      data.scanned ? "Yes" : "No"
    }}</span>

    <span slot="scanned_date" slot-scope="data">
      {{ data.scanned_date ? data.scanned_date : "N/A" }}
    </span>

    <span slot="payment_mode" slot-scope="data">{{ data.payment_mode }}</span>

    <span slot="status" slot-scope="data">
      <a-tag :color="getStatusColor(data.status)">
        {{ data.status }}
      </a-tag>
    </span>

    <span slot="action" slot-scope="data, record, index">
      <a @click="viewTransactions(data, record, index)">View</a>
    </span>
  </a-table>
</template>

<script>
import ProfileMixin from "@/mixins/Profile";
import { mapState } from "vuex";

export default {
  name: "transactions-table",
  mixins: [ProfileMixin],
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ["10", "20", "50", "100"],
        hideOnSinglePage: true,
      }),
    },
  },
  data() {
    return {
      showSuccessModal: false,
      showFailureModal: false,
      visible: false,
      transaction: {},
      columns: [
        {
          title: "Facility",
          key: "entity",
          rowKey: "_id",
          scopedSlots: { customRender: "entity" },
        },
        {
          title: "Total Price",
          key: "total_price",
          scopedSlots: { customRender: "total_price" },
        },
        {
          title: "Transaction Ref",
          key: "transaction_ref",
          scopedSlots: { customRender: "transaction_ref" },
        },
        {
          title: "Scanned",
          key: "scanned",
          scopedSlots: { customRender: "scanned" },
        },
        {
          title: "Scanned Date",
          key: "scanned_date",
          scopedSlots: { customRender: "scanned_date" },
        },
        {
          title: "Payment Mode",
          key: "payment_mode",
          scopedSlots: { customRender: "payment_mode" },
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      customRow: (record) => {
        return {
          on: {
            click: (event) => {
              this.viewTransactions(record);
            },
          },
        };
      },
    };
  },
  computed: {
    ...mapState("tourist", ["loadingGet"]),
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.$emit("paginationChange", pagination);
    },
    viewTransactions(transaction) {
      this.$router.push({
        path: `/${this.getUserAccountProfileModule}/transaction/${transaction._id}`,
      });
    },
    getStatusColor(status) {
      return status === "success" ? "green" : "yellow";
    },
    getServiceTitle(data) {
      const service_title = this.getValueFromSource(data, "service.title");
      const service_vehicle = this.getValueFromSource(
        data,
        "service.vehicle_type"
      );

      if (service_title) {
        return service_title;
      }

      if (service_vehicle) {
        return `Transport Rental - ${this.getValueFromSource(
          data,
          "service.vehicle_model"
        )} - ${this.getValueFromSource(data, "service.vehicle_year")}`;
      }
      return "";
    },
  },
};
</script>

<style>
td {
  cursor: pointer;
}
</style>
