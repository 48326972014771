<template>
  <div>
    <div class="serviceHeader">
      <div class="font-size-36 font-weight-bold text-dark mb-4">
        Transactions
      </div>
    </div>
    <a-tabs :default-active-key="payment_mode" @change="onPaymentModeChanged">
      <a-tab-pane key="all">
        <span slot="tab">
          <a-icon type="bar-chart" />
          All
        </span>

        <transactions-listing
          :tableData="transactions"
          :pagination="getPagination"
          @paginationChange="onPaginationUpdate"
        />
      </a-tab-pane>

      <a-tab-pane key="electronic">
        <span slot="tab">
          <a-icon type="wallet" />
          Electronic
        </span>

        <transactions-listing
          :tableData="transactions"
          :pagination="getPagination"
          @paginationChange="onPaginationUpdate"
        />
      </a-tab-pane>

      <a-tab-pane key="cash">
        <span slot="tab">
          <a-icon type="dollar" />
          Cash
        </span>
        <transactions-listing
          :tableData="transactions"
          :pagination="getPagination"
          @paginationChange="onPaginationUpdate"
        />
      </a-tab-pane>
    </a-tabs>

    <a-modal :footer="null" v-model="visible" title="Transaction">
      <a-row>
        <a-row>
          <a-form-item label="Service Price">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'service_price')"
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Total Price">
            <a-input
              :disabled="true"
              :value="
                $options.filters.formatCurrency(
                  getValueFromSource(transaction, 'total_price')
                )
              "
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Transaction Number">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'transaction_ref')"
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Scanned">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'scanned') ? 'Yes' : 'No'"
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Scanned Date">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'scanned_date')"
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Status">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'status')"
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Order Qty">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'order_qty')"
            /> </a-form-item
        ></a-row>
        <a-row>
          <a-form-item label="Service">
            <a-input
              :disabled="true"
              :value="getValueFromSource(transaction, 'service.title')"
            /> </a-form-item
        ></a-row>
      </a-row>
    </a-modal>

    <TransactionFailedModal
      :showModal="showFailureModal"
      @close-modal="() => (showFailureModal = false)"
    />

    <TransactionSuccesModal
      :showModal="showSuccessModal"
      @close-modal="() => (showSuccessModal = false)"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";

import {
  GET_TRANSACTIONS,
  VERIFY_PAYSTACK_TRANSACTION,
  GET_PAYMENT_SERVICE,
} from "@/store/actions";

import { isEmpty, get } from "lodash";

import ProfileMixin from "@/mixins/Profile";
import TransactionSuccesModal from "@/components/transactions/TransactionSuccessModal.vue";
import TransactionFailedModal from "@/components/transactions/TransactionFailedModal.vue";
import TransactionsListing from "./transactions-listing.vue";

export default {
  components: {
    TransactionSuccesModal,
    TransactionFailedModal,
    TransactionsListing,
  },
  mixins: [ProfileMixin],
  data() {
    return {
      showSuccessModal: false,
      showFailureModal: false,
      visible: false,
      transactions: [],
      transaction: {},
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
      payment_mode: "all",
    };
  },
  computed: {
    orders() {
      return this.transactions
        .map((transaction) => {
          return transaction.orders.map((orders) => {
            return {
              ...transaction,
              transaction_id: transaction._id,
              ...orders,
            };
          });
        })
        .flat();
    },
    getPagination() {
      return {
        current: this.currentPage,
        limit: this.pageSize,
        total: this.totalPages,
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        hideOnSinglePage: true,
      };
    },
  },
  async mounted() {
    const { payment_service } = await this.getPaymentService();
    const { reference } = this.$route.query;

    this.$router.replace({ query: {} }).catch(() => {});
    this.queryTransactions();

    if (payment_service === "paystack" && reference) {
      const { success } = await this.verifyTransaction(reference);

      if (success) {
        this.showSuccessModal = true;
      } else {
        this.showFailureModal = true;
      }
    }

    if (payment_service === "flutterwave") {
      const keys = Object.keys(this.$route.query);

      if (keys.length > 0 && keys.includes("status")) {
        if (this.$route.query && this.$route.query.status === "successful") {
          this.showSuccessModal = true;
        }
        if (this.$route.query && this.$route.query.status !== "successful") {
          this.showFailureModal = true;
        }
      }
    }

    const { transaction_id } = this.$route.query;
    if (transaction_id) {
      const transaction = this.orders.find(
        (order) => order.transaction_id === transaction_id
      );
      if (transaction) {
        this.viewTransactions(transaction);
      }
    }
  },
  methods: {
    ...mapActions("tourist", {
      fetchTransactions: GET_TRANSACTIONS,
      verifyTransaction: VERIFY_PAYSTACK_TRANSACTION,
      getPaymentService: GET_PAYMENT_SERVICE,
    }),
    onPaginationUpdate({ current, pageSize }) {
      this.currentPage = current;
      this.pageSize = pageSize;
      this.queryTransactions();
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    getStatusColor(status) {
      return status === "success" ? "green" : "yellow";
    },
    viewTransactions(transaction) {
      this.$router.push({
        path: `/tourist/transaction/${transaction.transaction_id}`,
      });
    },
    getServiceTitle(data) {
      if (data.service) {
        if (data.service.title) {
          return data.service.title || "";
        } else {
          if (data.service.vehicle_model || data.service.vehicle_type) {
            return `Transport Rental - ${data.service.vehicle_model} - ${data.service.vehicle_year}`;
          }
        }
      }
      return "";
    },
    onPaymentModeChanged(payment_mode) {
      console.log("payment_mode", payment_mode);
      if (payment_mode !== this.payment_mode) {
        this.payment_mode = payment_mode;
        this.currentPage = 1;
        this.queryTransactions();
      }
    },
    async queryTransactions() {
      try {
        const query = {
          page: this.currentPage,
          limit: this.pageSize,
          payment_mode: this.payment_mode,
        };

        const { data, pagination } = await this.fetchTransactions(query);
        this.transactions = data;

        const { totalRecords } = pagination;
        this.totalPages = totalRecords;

        const hasPreviousQuery = this.$route.query;

        if (!isEmpty(hasPreviousQuery)) {
          this.$router.replace({ query: {} }).catch((e) => {});
        }

        const urlQuery = {
          page: query.page,
          limit: query.limit,
        };

        this.$router
          .replace({
            query: urlQuery,
          })
          .catch((e) => {});
      } catch (error) {
        this.$notification.error({
          message: `Error fetching transactions`,
        });
      }
    },
  },
};
</script>
<style scoped>
.ant-input.ant-input-disabled {
  background-color: white;
}
</style>
